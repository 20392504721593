import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../api/api";
import Cookies from "js-cookie";

export default function Signin() {
  const token = Cookies.get('auth_token_admin')
  const formHandler = async (e) => {
    e.preventDefault();
    const email = e.target.email.value
    const password = e.target.password.value
    if (email && password) {
      try {
        const res = await api.post('/api/users/login-admin', { email, password })
        if (res.status === 200) {
          toast.success(res.data.message)
          console.log(res.data)
          Cookies.set('auth_token_admin', res.data.token)
          window.location.href = '/'
        }
      } catch (error) {
        toast.error(error.response.data.message)
      }
    }
    else {
      toast.error("Please enter email and password")
    }
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [token])
  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">Linear Hub</Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={formHandler}>
            <div className="mb-4">
              <Form.Label >Email address</Form.Label>
              <Form.Control name="email" type="text" placeholder="Enter your email address" />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">Password <Link to="">Forgot password?</Link></Form.Label>
              <Form.Control type="password" placeholder="Enter your password"
                name="password"
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Sign In</Button>

            <div className="divider"><span>or sign in with</span></div>

            <Row className="gx-2">
              <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
              <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
            </Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          Don't have an account? <Link to="/pages/signin">Create an Account</Link>
        </Card.Footer>
      </Card>
    </div>
  )
}