import axios from "axios";
import Cookies from "js-cookie";

export const server = 'https://server.linearhub.com/'
export const local = 'http://localhost:4000/'
export const localURL = server

export const api = axios.create({
    baseURL: server,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('auth_token_admin')}`,
    },
})