import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isExpired, decodeToken } from "react-jwt";
import { api } from '../api/api';

const AdminRequired = ({ children }) => {
    const navigate = useNavigate()
    const token = Cookies.get('auth_token_admin')
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {

        const decode = decodeToken(token)
        if (isExpired(token)) {
            Cookies.remove('auth_token_admin')
            navigate('/pages/signin')
        }
        else {
            if (decode.email) {
                api.post(`/api/users/verify-admin/${decode.email}`)
                    .then(res => {
                        if (res.data.varifyed) {
                            setIsAdmin(true)
                        }
                    })
            }
        }
    }, [token])

    if (!token) {
        navigate('/pages/signin')
    }
    else if (isAdmin) {
        return children
    }
    else{
        return <>Loading....</>
    }

};

export default AdminRequired;