import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import { api, localURL } from '../api/api';
import axios from 'axios';
import toast from 'react-hot-toast';

const Banners = () => {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    // this is just for comment 
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);
    const [banners, setBanners] = useState([]);
    useEffect(() => {
        api.get('/api/banners')
            .then((res) => {
                setBanners(res.data);
            })
    }, [])
    const uploadBanner = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('product', e.target.image.files[0]);
        try {
            const res = await fetch('https://server.linearhub.com/upload-product', {
                method: 'POST',
                headers: {
                    code: "banners"
                },
                body: formData
            })
            const data = await res.json()
            const image = data.images[0].image
            const link = e.target.link.value
            const resBanner = await api.post('/api/banners', {
                image: image,
                link: link
            })
            if (resBanner.status === 200) {
                setBanners([...banners, resBanner.data])
                toast.success("Banner Added")
                e.target.reset()
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
    const updateBanner = (e) => {
        e.preventDefault()
        const id = e.target.id.value
        const link = e.target.link.value
        if (e.target.image.files[0]) {
            
        }
    }
    const deleteBanner = (id) => {
        api.delete(`/api/banners/${id}`)
            .then(res => {
                setBanners(banners.filter((banner) => banner._id !== id))
                toast.success("Banner Deleted")
            })
            .catch(err => toast.error(err.response.data.message))
    }
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <h3>Add A Banner</h3>
                <div>
                    <form onSubmit={uploadBanner}>
                        <div className="mb-3">
                            <label htmlFor="banner-link" className="form-label">Page Path</label>
                            <input
                                name='link'
                                type="text" className="form-control" id="banner-link" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="banner-image" className="form-label">Image</label>
                            <input name='image' type="file" className="form-control" id="banner-image" />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
                <h3 className="main-title mt-3">All Banners</h3>

                {
                    banners.map((banner, key) => {
                        return <form key={key} onSubmit={updateBanner} className='p-3 border'>
                            <div>
                                <img src={localURL + banner.image} alt='' width={300} />
                            </div>
                            <div className="my-3">
                                <label htmlFor="banner-image" className="form-label">Image</label>
                                <input name='image' type="file" className="form-control" id="banner-image" />
                            </div>
                            <input
                                className='d-none'
                                name='id' defaultValue={banner._id} />
                            <div className="mt-3">
                                <label htmlFor="banner-link" className="form-label">Page Path</label>
                                <input
                                    defaultValue={banner.link}
                                    name='link'
                                    type="text" className="form-control" id="banner-link" />
                            </div>
                            <button type="submit" className="btn btn-primary mt-3">Update</button>
                            <button
                                onClick={() => deleteBanner(banner._id)}
                                type="submit" className="btn btn-danger ms-2 mt-3">Delete</button>
                        </form>
                    })
                }

            </div>
        </React.Fragment>
    );
};

export default Banners;