import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import { api, localURL } from '../api/api';
import { Link } from 'react-router-dom';

const AllProducts = () => {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);
    const [products, setProducts] = useState([])
    const [repatch, setRepatch] = useState()
    useEffect(() => {
        api.get('/api/products/all')
            .then(res => setProducts(res.data))
            .catch(err => console.log(err))
    }, [repatch])
    const stockHandler = (id, condition) => {
        if (condition === "true") {
            api.put('/api/products/stock/' + id, { inStock: true })
                .then(res => setRepatch(res.data))
        }
        else {
            api.put('/api/products/stock/' + id, { inStock: false })
                .then(res => setRepatch(res.data))
        }
    }
    const deleteProduct = (id) => {
        const isAgree = window.confirm("Are you sure you want to delete?");
        if (isAgree) {
            api.delete('/api/products/' + id)
                .then(res => setRepatch(res.data))
        }
    }
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className='row gap-5'>
                    <h5 className='p-0 m-0'>Total {products.length}</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Image</th>
                                <th scope="col">Name</th>
                                <th scope="col">Code</th>
                                <th scope="col">Main Price</th>
                                <th scope="col">Discount</th>
                                <th scope="col">Price</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                products.map((product, index) =>
                                    <tr key={index}>
                                        <th scope="row">1</th>
                                        <td>
                                            <img height={'100px'} src={localURL + product.cover} alt="" />
                                        </td>
                                        <td style={{
                                            fontSize: '14px'
                                        }}>
                                            {product.name}
                                        </td>
                                        <td>{product.code}</td>
                                        <td>{product.price} Taka</td>
                                        <td>{product.discount_percentage} %</td>
                                        <td>{
                                            product.price - (product.price * product.discount_percentage) / 100
                                        }</td>
                                        <td>
                                            <Link to={`/dashboard/product/detail/${product._id}`} className="btn btn-primary">See Details</Link>

                                            {
                                                product.inStock ?
                                                    <button className='btn btn-primary ms-2'
                                                        onClick={() => stockHandler(product._id, "false")}
                                                    >
                                                        In Stock
                                                    </button>
                                                    :
                                                    <button className='btn btn-danger ms-2'
                                                        onClick={() => stockHandler(product._id, "true")}
                                                    >
                                                        Out of stock
                                                    </button>
                                            }
                                            <button className='btn btn-danger ms-2'
                                                onClick={() => deleteProduct(product._id)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AllProducts;