import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import { api } from '../api/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
const AllOrders = () => {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);
    const [allOrders, setAllOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [repatch, setRepatch] = useState(0)
    useEffect(() => {
        api.get('/api/orders')
            .then(res => {
                setOrders(res.data)
                setAllOrders(res.data)
            })
    }, [repatch])
    const changeStatus = (status, id) => {
        try {
            api.put(`/api/orders/${id}`, { status: status, updateType: 'status' })
                .then(res => {
                    setRepatch(repatch + 1)
                    toast.success("Status Updated Done")
                })
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
    const searchOrder = (e) => {
        e.preventDefault();
        const order_id = e.target[0].value
        if (order_id) {
            try {
                api.get(`/api/orders/get/${order_id}`)
                    .then(res => {
                        setOrders(res.data)
                    })
            } catch (error) {
                toast.error(error.response.data.message)
            }
        }
    }
    const findByDate = (date) => {
        const data = allOrders.filter(order => moment(order.createdAt).format('YYYY-MM-DD') === date)
        setOrders(data)
    }
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <h2>All Orders</h2>
                <button onClick={() => setRepatch(repatch + 1)} className='btn btn-sm btn-primary'>Reload</button>
                <div className='d-flex align-items-center gap-3'>
                    <form
                        onSubmit={searchOrder}
                        className="input-group mb-3 mt-3" style={{ maxWidth: "400px" }}>
                        <input required type="text" className="form-control" placeholder="Paste Your Order Id Email Or Phone" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn btn-outline-secondary" type="submit" id="button-addon2">Search</button>
                    </form>
                    <input
                        type="date"
                        className="form-control"
                        onChange={e => findByDate(e.target.value)}
                        placeholder="Paste Your Order Id"
                    />
                    <form
                        onSubmit={searchOrder}
                        className="input-group mb-3 mt-3" style={{ maxWidth: "400px" }}>
                        <select
                            required
                            className="form-select" >
                            <option value={""}>Select Status</option>
                            <option value={"pending"}>Pending</option>
                            <option value={"processing"}>Processing</option>
                            <option value={"shipped"}>Shipped</option>
                            <option value={"delivered"}>Delivered</option>
                            <option value={"cancel"}>Cancel</option>
                        </select>

                        <button className="btn btn-outline-secondary" type="submit" id="button-addon2">Search</button>
                    </form>
                </div>

                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Time</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Statue</th>
                            <th scope="col">Action</th>
                            <th scope="col">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orders.map((order, index) => {
                                return <tr key={index}>
                                    <th scope="row">#{order.order_id}</th>
                                    <td>
                                        <Link to={`/dashboard/product/detail/${order.order[0].product_id}`}>
                                            {order.order[0].product_name.slice(0, 30)}
                                        </Link>
                                    </td>
                                    <td>{
                                        moment(order.createdAt).format('MMMM Do YYYY, h:mm a')
                                    }</td>
                                    <td>{order.address.name}</td>
                                    <td>{order.email}</td>
                                    <td>{order.address.phone}</td>
                                    <td>
                                        <span className=''
                                            style={{
                                                fontWeight: '500',
                                            }}
                                        >{order.status}</span>
                                    </td>
                                    <td>
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                                Change  Status
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <button
                                                        onClick={() => changeStatus('processing', order._id)}
                                                        className='btn btn-sm btn-outline-primary w-100'>
                                                        Processing
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        onClick={() => changeStatus('pending', order._id)}
                                                        className='btn btn-sm btn-warning mt-2 w-100'>
                                                        Pending
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        onClick={() => changeStatus('shipped', order._id)}
                                                        className='btn btn-sm btn-primary w-100 mt-2' >
                                                        Shipped
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        onClick={() => changeStatus('delivered', order._id)}
                                                        className='btn btn-sm btn-success w-100 mt-2'>
                                                        Delivered
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        onClick={() => changeStatus('canceled', order._id)}
                                                        className='btn btn-sm btn-danger w-100 mt-2'>
                                                        Canceled
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <Link
                                            to={`/dashboard/orders/detail/${order.order_id}`}
                                            className="btn btn-sm btn-primary">
                                            Details
                                        </Link>
                                    </td>
                                </tr>
                            })
                        }

                    </tbody>
                </table>

            </div>
        </React.Fragment>
    );
};

export default AllOrders;