import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import { api, localURL } from '../api/api';
import { useParams } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import './Product.css';
import toast from 'react-hot-toast';
const ProductDetails = () => {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);
    const params = useParams()
    const [products, setProducts] = useState({
        name: "", //added
        code: "", //added
        price: 0, //added
        discount_percentage: 0, //added
        inStock: true, //added
        sold: 0, //
        available: 0, //added
        visit: 0, // added
        category: "", //added
        brand: "", //added
        productDescription: "", //added
        variant: [],
        spacification: [],
        images: [],
        key_features: [],
        descriptions: [],
        bestDeal: false,
        topTen: false,
        new_arrival: false,
        topSelling: false,
        popular: false,
        flash_sale: false,
        hotSales: false
    })
    useEffect(() => {
        api.get(`/api/products/${params.id}`)
            .then(res => setProducts(res.data))
            .catch(err => console.log(err))

    }, [])

    const updateProduct = async (e) => {
        e.preventDefault();
        const newData = {
            ...products
        }

        try {
            const res = await api.put(`/api/products/${params.id}`, newData)
            if (res.status === 200) {
                toast.success("Product Updated")
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
    const [descKey, setDescKey] = useState("")
    const [descValue, setDescValue] = useState("")
    const [keyValueKey, setkeyValueKey] = useState("")
    const [keyvalueValue, setkeyValueValue] = useState("")
    const [spacificationValue, setSpacificationValue] = useState("")
    const [spacificationKey, setSpacificationKey] = useState("")
    const uploadImages = (e) => {
        if (e.target.files) {
            const formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('product', e.target.files[i]);
            }
            fetch(`${localURL}upload-product`, {
                method: 'POST',
                headers: {
                    code: products.code
                },
                body: formData
            })
                .then(res => res.json())
                .then(res => setProducts((old) => ({ ...old, images: [...res.images, ...old.images] })))
                .catch(err => console.log(err))
        }
    }
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <h2 className='py-2'> Details Of : {products.name}</h2>
                <div className='d-flex gap-5'>
                    <div className="card bg-white" style={{ minWidth: '400px', maxWidth: '400px' }}>
                        <div className="card-header">
                            <h5 className='mb-0'> Product Images</h5>
                        </div>
                        <div className="card-body">
                            Product Tags
                            <div className='mt-2'>
                                <ReactTags
                                    tags={products.tags}
                                    handleDelete={(i) => {
                                        setProducts((old) => {
                                            return {
                                                ...old,
                                                tags: old.tags.filter((tag, index) => index !== i)
                                            }
                                        })
                                    }}
                                    handleAddition={(tag) => {
                                        setProducts((old) => {
                                            return {
                                                ...old,
                                                tags: [...old.tags, tag]
                                            }
                                        })
                                    }}
                                    inputFieldPosition="bottom"
                                />
                            </div>
                            <div className="my-3">
                                <label htmlFor="formFile" className="form-label">
                                    Upload Product Images
                                </label>
                                <input
                                    onChange={(e) => uploadImages(e)}
                                    className="form-control" type="file" id="formFile"
                                    multiple
                                />
                            </div>

                            <h5 className='mt-3'>Product Thumbnail</h5>
                            <img style={{ height: '200px', width: 'auto' }} src={localURL + products?.cover} className="card-img-top" alt="" />
                            <div className='mt-3'>
                                <h5>Product Images</h5>
                            </div>
                            <div className='d-flex gap-3 flex-wrap justify-content-center' style={{
                                height: '400px',
                                overflowY: 'scroll'
                            }}>
                                {
                                    products?.images?.map((pr, key) => {
                                        return (
                                            <div key={key} className='position-relative border'>
                                                <img onClick={() => {
                                                    setProducts((old) => {
                                                        return {
                                                            ...old,
                                                            cover: pr.image
                                                        }
                                                    })
                                                }}
                                                    style={{ width: '170px' }} src={localURL + pr.image} className="card-img-top" alt={pr} />
                                                <button
                                                    onClick={() => {
                                                        setProducts((old) => {
                                                            return {
                                                                ...old,
                                                                images: old.images.filter((im, k) => k !== key)
                                                            }
                                                        })
                                                    }}
                                                    className='btn btn-danger position-absolute top-0 end-0 btn-sm'>X</button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                    <div className='card bg-white w-100'>
                        <div className="card-header">
                            <h5 className='mb-0'>Product Details</h5>
                        </div>

                        <form
                            onSubmit={updateProduct}
                            className="card-body position-relative"

                        >
                            <div className='row mt-3 px-3 gap-3'>
                                <div className="form-check col-2 ms-3">
                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" checked={products?.bestDeal}
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    bestDeal: e.target.checked
                                                }
                                            })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Best Deals
                                    </label>
                                </div>
                                <div className="form-check col-2 ms-3">
                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" checked={products?.topTen}
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    topTen: e.target.checked
                                                }
                                            })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Top Ten
                                    </label>
                                </div>
                                <div className="form-check col-3 ms-3">
                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" checked={products?.topSelling}
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    topSelling: e.target.checked
                                                }
                                            })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Top Selling
                                    </label>
                                </div>
                                <div className="form-check col-2 ms-3">
                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" checked={products?.popular}
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    popular: e.target.checked
                                                }
                                            })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Populer Product
                                    </label>
                                </div>
                                <div className="form-check col-2 ms-3">
                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" checked={products?.flash_sale}
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    flash_sale: e.target.checked
                                                }
                                            })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Flash Sale
                                    </label>
                                </div>
                                <div className="form-check col-2 ms-3">
                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" checked={products?.hotSales}
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    hotSales: e.target.checked
                                                }
                                            })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Hot Sales
                                    </label>
                                </div>
                            </div>
                            {/* Product Name  */}
                            <div className="mb-3">
                                <label className="form-label">Product Name</label>
                                <input
                                    defaultValue={products?.name}
                                    type="text"
                                    name='name'
                                    value={products?.name}
                                    onChange={(e) => setProducts((old) => {
                                        return {
                                            ...old,
                                            name: e.target.value
                                        }
                                    })}
                                    className="form-control"
                                />
                            </div>
                            <div className='row '>
                                {/* Product Code */}
                                <div className="mb-3 col-6">
                                    <label className="form-label">Product Code</label>
                                    <input
                                        type="text"
                                        name='code'
                                        className="form-control"
                                        value={products?.code}
                                        onChange={(e) => setProducts((old) => {
                                            return {
                                                ...old,
                                                code: e.target.value
                                            }
                                        })}
                                    />
                                </div>
                                {/* Product Category */}
                                <div className="mb-3 col-6">
                                    <label className="form-label">Product Category</label>
                                    <input
                                        type="text"
                                        name='category'
                                        className="form-control"
                                        value={products?.category}
                                        onChange={(e) => setProducts((old) => {
                                            return {
                                                ...old,
                                                category: e.target.value
                                            }
                                        })}
                                    />
                                </div>
                                {/* Product Variants */}
                                <div className="mb-3 col-6">
                                    <label className="form-label">Product Variants</label>
                                    <ReactTags
                                        tags={products.variant}
                                        suggestions={colors}
                                        handleDelete={(i) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    variant: old.variant.filter((tag, index) => index !== i)
                                                }
                                            })
                                        }}
                                        handleAddition={(tag) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    variant: [...old.variant, tag]
                                                }
                                            })
                                        }}
                                        inputFieldPosition="bottom"
                                    />
                                </div>
                                {/* product Brand  */}
                                <div className="mb-3 col-6">
                                    <label className="form-label">Brand</label>
                                    <input
                                        type="text"
                                        name='brand'
                                        className="form-control"
                                        value={products?.brand}
                                        onChange={(e) => setProducts((old) => {
                                            return {
                                                ...old,
                                                brand: e.target.value
                                            }
                                        })}
                                    />
                                </div>
                                {/* Price */}
                                <div className="mb-3 col-4">
                                    <label className="form-label">Product Price</label>
                                    <input
                                        type="number"
                                        name='price'
                                        className="form-control"
                                        value={products?.price}
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    price: Number(e.target.value)
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                {/* Discount */}
                                <div className="mb-3 col-4">
                                    <label className="form-label">Discount %</label>
                                    <input
                                        type="number"
                                        name='discount'
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    discount_percentage: Number(e.target.value)
                                                }
                                            })
                                        }}
                                        className="form-control"
                                        value={products?.discount_percentage}
                                    />
                                </div>
                                {/* Sell Price */}
                                <div className="mb-3 col-4">
                                    <label className="form-label">Sell Price</label>
                                    <input
                                        readOnly
                                        type="number"
                                        name='price'
                                        className="form-control"
                                        value={
                                            products?.price - (products?.price * products?.discount_percentage) / 100
                                        }
                                    />
                                </div>
                                {/* sold  */}
                                <div className="mb-3 col-4">
                                    <label className="form-label">Sold</label>
                                    <input
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    sold: Number(e.target.value)
                                                }
                                            })
                                        }}
                                        type="number"
                                        name='sold'
                                        className="form-control"
                                        value={
                                            products.sold
                                        }
                                    />
                                </div>
                                {/* Is InStrock  */}
                                <div className="form-check col-12 ms-3">
                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" checked={products?.inStock}
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    inStock: e.target.checked
                                                }
                                            })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        inStrock
                                    </label>
                                </div>
                                {/* Product Descriptions  */}
                                <div className="mb-3 col-12 mt-2 border p-3">
                                    <label className="form-label">Product Description</label>
                                    <textarea
                                        name='price'
                                        className="form-control"
                                        style={{
                                            height: '100px',
                                        }}
                                        value={
                                            products.productDescription
                                        }
                                        onChange={(e) => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    productDescription: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className='col-12 mt-4 p-3 border shadow-sm'>
                                    <h5>Descriptions</h5>
                                    {
                                        products?.descriptions?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <button
                                                        onClick={() => {
                                                            setProducts((old) => {
                                                                return {
                                                                    ...old,
                                                                    descriptions: old.descriptions.filter((des, key) => key !== index)
                                                                }
                                                            })
                                                        }}
                                                        className='btn btn-danger btn-sm'>x</button>
                                                    <h6>TITLE :  {item.key}</h6>
                                                    <p>{item.value}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <div>
                                        <div className="col-8">
                                            <label className="form-label">Key</label>
                                            <input
                                                type="text"
                                                onChange={(e) => {
                                                    setDescKey(e.target.value)
                                                }}
                                                value={descKey}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="mb-3 col-8">
                                            <label className="form-label">Value</label>
                                            <textarea
                                                onChange={(e) => {
                                                    setDescValue(e.target.value)
                                                }}
                                                value={descValue}
                                                className="form-control"
                                            />
                                        </div>
                                        <div onClick={() => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    descriptions: [...old.descriptions, { key: descKey, value: descValue }]
                                                }
                                            })
                                            setDescKey('')
                                            setDescValue('')
                                        }} className='btn btn-sm btn-primary'>Add</div>
                                    </div>
                                </div>
                                {/* Key Features  */}
                                <div className='col-12 mt-4 border shadow-sm p-3'>
                                    <h5>Key Features</h5>
                                    {
                                        products?.spacification?.map((item, index) => {
                                            return (
                                                <div key={index} className='position-relative'>
                                                    <div
                                                        onClick={() => {
                                                            setProducts((old) => {
                                                                return {
                                                                    ...old,
                                                                    spacification: old.spacification.filter((des, key) => key !== index)
                                                                }
                                                            })
                                                        }}
                                                        style={{ float: 'right', position: 'absolute', top: '0', right: '0' }}
                                                        className='btn btn-danger btn-sm'>x</div>
                                                    <h6 className='m-0'>TITLE :  {item.key}</h6>
                                                    <p>{item.value}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <div>
                                        <div className="col-8">
                                            <label className="form-label">Key</label>
                                            <input
                                                type="text"
                                                onChange={(e) => {
                                                    setSpacificationKey(e.target.value)
                                                }}
                                                value={spacificationKey}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="mb-3 col-8">
                                            <label className="form-label">Value</label>
                                            <textarea
                                                onChange={(e) => {
                                                    setSpacificationValue(e.target.value)
                                                }}
                                                value={spacificationValue}
                                                className="form-control"
                                            />
                                        </div>
                                        <div onClick={() => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    spacification: [...old.spacification, { key: spacificationKey, value: spacificationValue }]
                                                }
                                            })
                                            setSpacificationKey('')
                                            setSpacificationValue('')
                                        }} className='btn btn-sm btn-primary'>Add</div>
                                    </div>
                                </div>
                                {/* Spacifications  */}
                                <div className='col-12 mt-4 border shadow-sm p-3'>
                                    <h5>Spacifications</h5>
                                    {
                                        products?.key_features?.map((item, index) => {
                                            return (
                                                <div key={index} className='position-relative'>
                                                    <div
                                                        onClick={() => {
                                                            setProducts((old) => {
                                                                return {
                                                                    ...old,
                                                                    key_features: old.key_features.filter((des, key) => key !== index)
                                                                }
                                                            })
                                                        }}
                                                        style={{ float: 'right', position: 'absolute', top: '0', right: '0' }}
                                                        className='btn btn-danger btn-sm'>x</div>
                                                    <h6 className='m-0'>TITLE :  {item.key}</h6>
                                                    <p>{item.value}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <div>
                                        <div className="col-8">
                                            <label className="form-label">Key</label>
                                            <input
                                                type="text"
                                                onChange={(e) => {
                                                    setkeyValueKey(e.target.value)
                                                }}
                                                value={keyValueKey}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="mb-3 col-8">
                                            <label className="form-label">Value</label>
                                            <textarea
                                                onChange={(e) => {
                                                    setkeyValueValue(e.target.value)
                                                }}
                                                value={keyvalueValue}
                                                className="form-control"
                                            />
                                        </div>
                                        <div onClick={() => {
                                            setProducts((old) => {
                                                return {
                                                    ...old,
                                                    key_features: [...old.key_features, { key: keyValueKey, value: keyvalueValue }]
                                                }
                                            })
                                            setkeyValueKey('')
                                            setkeyValueValue('')
                                        }} className='btn btn-sm btn-primary'>Add</div>
                                    </div>
                                </div>
                            </div>
                            <button className='btn btn-primary mt-4'>
                                Update Product
                            </button>
                            <button className='btn btn-primary mt-4 position-absolute top-0 end-0'>
                                Update Product
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProductDetails;

const colors = [
    { id: '1', text: 'red' },
    { id: 'black', text: 'black' },
    { id: 'green', text: 'green' },
    { id: 'blue', text: 'blue' },
    { id: 'yellow', text: 'yellow' },
    { id: 'white', text: 'white' },
    { id: 'orange', text: 'orange' },
    { id: 'purple', text: 'purple' },
    { id: 'pink', text: 'pink' },
    { id: 'gray', text: 'gray' },
    { id: 'brown', text: 'brown' },
    { id: 'cyan', text: 'cyan' },
    { id: 'magenta', text: 'magenta' },
    { id: 'olive', text: 'olive' },
    { id: 'navy', text: 'navy' },
    { id: 'teal', text: 'teal' },
    { id: 'lime', text: 'lime' },
    { id: 'maroon', text: 'maroon' },
    { id: 'silver', text: 'silver' },
    { id: 'gold', text: 'gold' },
    { id: 'indigo', text: 'indigo' },
    { id: 'violet', text: 'violet' },
    { id: 'crimson', text: 'crimson' },
    { id: 'aqua', text: 'aqua' },
    { id: 'fuchsia', text: 'fuchsia' },
    { id: 'teal', text: 'teal' },
    { id: 'lavender', text: 'lavender' },
]